import React from "react";
import Banner from '../assets/images/banner/delegation program.png'
function DelegationProgram() {
    return (
        <div>
            <section className="banner">
                <div className="wrapper container">
                    <div className="flex-home">
                        <h2>
                            Delegation <br /> program
                        </h2>
                        <p>Coming Soon</p>
                    </div>
                    <div className="flex-img">
                        <img src={Banner} alt="" />
                    </div>
                </div>
            </section>
        </div>
    );
}

export default DelegationProgram;
